import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faUser, faPhone, faEnvelope, faAddressCard, faNotesMedical, faBroadcastTower, faChevronDown, faChevronUp, faEdit } from '@fortawesome/free-solid-svg-icons';

const EmergencyContactGroup = () => {
  const [groups, setGroups] = useState(() => {
    const storedGroups = localStorage.getItem('emergencyContactGroups');
    return storedGroups ? JSON.parse(storedGroups) : [];
  });
  const [groupName, setGroupName] = useState('');
  const [contactForm, setContactForm] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    relationship: '',
    role: '',
    medicalInfo: '',
    radioChannel: ''
  });
  const [expandedGroupId, setExpandedGroupId] = useState(null);

  const roles = ['Family Member', 'Doctor', 'Teacher', 'Neighbor', 'Friend', 'Emergency Service', 'Other'];

  useEffect(() => {
    localStorage.setItem('emergencyContactGroups', JSON.stringify(groups));
  }, [groups]);

  const addGroup = () => {
    if (!groupName) return;
    const newGroup = { id: Date.now(), name: groupName, contacts: [], isEditing: false };
    setGroups([newGroup, ...groups]); // Add new group at the beginning
    setExpandedGroupId(newGroup.id); // Automatically expand the new group
    setGroupName('');
  };

  const toggleGroupExpansion = (groupId) => {
    setExpandedGroupId(expandedGroupId === groupId ? null : groupId);
  };

  const addContact = (groupId) => {
    if (!contactForm.name) return;
    setGroups(groups.map(group => 
      group.id === groupId 
        ? { ...group, contacts: [...group.contacts, { ...contactForm, id: Date.now() }] } 
        : group
    ));
    setContactForm({ name: '', phone: '', email: '', address: '', relationship: '', role: '', medicalInfo: '', radioChannel: '' });
  };

  const deleteContact = (groupId, contactId) => {
    setGroups(groups.map(group => 
      group.id === groupId 
        ? { ...group, contacts: group.contacts.filter(contact => contact.id !== contactId) } 
        : group
    ));
  };

  const deleteGroup = (groupId) => {
    setGroups(groups.filter(group => group.id !== groupId));
  };

  return (
    <div className="bg-gray-100 space-y-6 p-4 md:p-6 lg:p-8">
    <div className="px-2 sm:px-0">
    <p className=" font-semibold text-gray-900 text-xl">Emergency Contact Groups</p>
<p className="mt-1 text-sm text-gray-600 ">
  Create and manage groups of emergency contacts for various scenarios.   Simply add a new group name, then fill in the contact details for each member. You can expand any group to view, edit, or remove contact information as needed.

</p>
<p className="mt-2 text-sm text-gray-600">
  <strong>Example Groups:</strong>
</p>
<ul className="mt-2 text-sm text-gray-600 list-disc list-inside">
<li>
  <strong>Family Evacuation Plan:</strong> Parents, siblings, neighbors, and an emergency shelter hotline.
</li>

<li>
  <strong>Neighborhood Support Group:</strong> Neighbors, community leaders, emergency services, and volunteer supporters.
</li>

</ul>

    </div>
    <p className="font-semibold text-sm text-gray-900 text-center mt-5">

</p>
    <div className="mt-4">
      <input 
        type="text" 
        placeholder="New Group Name" 
        value={groupName} 
        onChange={(e) => setGroupName(e.target.value)} 
        className="block w-full rounded-md bg-white px-4 py-2 text-base text-gray-900 placeholder-gray-400 focus:outline-indigo-600"
      />
      <button 
        onClick={addGroup} 
        className="mt-3 rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500 flex items-center justify-center gap-x-2"
      >
        <FontAwesomeIcon icon={faPlus} /> Add Group
      </button>
    </div>
  
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 items-start">
      {groups.map(group => (
        <div key={group.id} className="divide-y divide-gray-200 rounded-lg bg-white  self-start">
          <div className="flex items-center justify-between p-4">
            <div className="flex truncate">
              <div className=' '>
              <h3 className="truncate text-lg font-medium text-gray-700">{group.name}</h3>
              <p className="text-xs text-gray-500">{group.contacts.length} Contact(s)</p>
              </div>
              <button onClick={() => toggleGroupExpansion(group.id)} className="ms-3 text-gray-500">
                <FontAwesomeIcon icon={expandedGroupId === group.id ? faChevronUp : faChevronDown} />
              </button>
            </div>
            
            <div className="flex items-center gap-x-4">
              
              <button onClick={() => deleteGroup(group.id)} className="text-red-500">
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
  
          {expandedGroupId === group.id && (
            <div className="p-4 border-b border-gray-200">
              <h4 className="text-sm font-semibold text-gray-700">Add New Contact</h4>
              <input type="text" placeholder="Full Name" value={contactForm.name} onChange={(e) => setContactForm({ ...contactForm, name: e.target.value })} className="block w-full rounded-md bg-light  px-4 py-2 mt-2" />
              <select value={contactForm.role} onChange={(e) => setContactForm({ ...contactForm, role: e.target.value })} className="block w-full rounded-md bg-light  px-4 py-2 mt-2">
                <option value="">Select Role</option>
                {roles.map(role => <option key={role} value={role}>{role}</option>)}
              </select>
              <input type="text" placeholder="Phone" value={contactForm.phone} onChange={(e) => setContactForm({ ...contactForm, phone: e.target.value })} className="block w-full rounded-md bg-light  px-4 py-2 mt-2" />
              <input type="email" placeholder="Email" value={contactForm.email} onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })} className="block w-full rounded-md bg-light px-4 py-2 mt-2" />
              <input type="text" placeholder="Address" value={contactForm.address} onChange={(e) => setContactForm({ ...contactForm, address: e.target.value })} className="block w-full rounded-md bg-light  px-4 py-2 mt-2" />
              <input type="text" placeholder="Relationship" value={contactForm.relationship} onChange={(e) => setContactForm({ ...contactForm, relationship: e.target.value })} className="block w-full rounded-md bg-light px-4 py-2 mt-2" />
              <input type="text" placeholder="Medical Info" value={contactForm.medicalInfo} onChange={(e) => setContactForm({ ...contactForm, medicalInfo: e.target.value })} className="block w-full rounded-md bg-light  px-4 py-2 mt-2" />
              <input type="text" placeholder="Radio Channel" value={contactForm.radioChannel} onChange={(e) => setContactForm({ ...contactForm, radioChannel: e.target.value })} className="block w-full rounded-md  bg-light px-4 py-2 mt-2" />
              <button onClick={() => addContact(group.id)} className="mt-3 rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white">
                Add Contact
              </button>
              
                
             
            </div>
          )}
          <div className="p-4">
                  {group.contacts.map(contact => (
                    <div key={contact.id} className="border-b border-gray-200 py-4">
                      <p><strong>Name:</strong> {contact.name}</p>
                      <p><strong>Role:</strong> {contact.role}</p>
                      <p><strong>Phone:</strong> {contact.phone}</p>
                      <p><strong>Email:</strong> {contact.email}</p>
                      <p><strong>Address:</strong> {contact.address}</p>
                      <p><strong>Relationship:</strong> {contact.relationship}</p>
                      <p><strong>Medical Info:</strong> {contact.medicalInfo}</p>
                      <p><strong>Radio Channel:</strong> {contact.radioChannel}</p>
                      <button onClick={() => deleteContact(group.id, contact.id)} className="mt-2 text-red-500">
                        <FontAwesomeIcon icon={faTrash} /> Delete Contact
                      </button>
                    </div>
                  ))}
                </div>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default EmergencyContactGroup;
