import React, { useState, useEffect, useRef } from 'react';

const items = [
    {
      quantity: "variable",
      unit: "gal.",
      item: "Water",
      description: "Buy containers that enable you to quickly and safely store all the water your family needs in an emergency. Have on hand one gallon of water per day, per person, for drinking and cooking.",
      buyLink: "https://www.amazon.com/WaterStorageCube-Collapsible-Container-Hurricane-Emergency/dp/B07FVJLYZ4/ref=sr_1_3?keywords=Water%252Bfor%252Bstorage&qid=1687917597&sr=8-3&th=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=0b8d08b33de2632263a94904dbf0ac57&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Can(s)",
      item: "Tuna or Salmon",
      description: "Most cans hold 5oz. A 5oz can serve the average adult per meal and half the amount for a kid.",
      buyLink: "https://www.amazon.com/BUMBLE-Chunk-Light-Canned-Protein/dp/B001UP2188/ref=sr_1_1_sspa?crid=397SPIQDMLA79&keywords=tuna&qid=1687918483&sprefix=tuna%252Caps%252C183&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=7f7b8390baf448603381e45e3de96d78&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Cup(s)",
      item: "Chili",
      description: "The serving size for canned chili is typically around 1 cup or 8 ounces for adults and half the amount for a kid per meal.",
      buyLink: "https://www.amazon.com/Campbells-Chunky-Soup-Chili-16-3/dp/B0B81VVXW2?ref_=ast_sto_dp&th=1&psc=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=16a13a088500471e24a02e06ef4464c1&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Cup(s)",
      item: "Beef Stew",
      description: "The serving size for canned beef stew is typically around 1 cup or 8 ounces for adults and half the amount for a kid per meal.",
      buyLink: "https://www.amazon.com/Dinty-Moore-Hearty-Meals-15-Ounce/dp/B002UC9I3Q/ref=sr_1_5?crid=10MPL3I676Q2R&keywords=Beef+Stew&qid=1687921395&s=grocery&sprefix=beef+stew%252Cgrocery%252C131&sr=1-5&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=9830ad08edc0e67cf78af5091df0a9a8&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Cup(s)",
      item: "Ravioli",
      description: "The serving size for Ravioli is typically around 1 cup or 8 ounces for adults and half the amount for a kid per meal.",
      buyLink: "https://www.amazon.com/Chef-Boyardee-Ravioli-7-5-Ounce-Microwavable/dp/B000MICPSA/ref=sr_1_5?crid=10BXFBXM1KMO8&keywords=Raviolis&qid=1687921774&s=grocery&sprefix=raviolis%252Cgrocery%252C125&sr=1-5&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=809900bec25584c1a3feb8d74fcf1889&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Can(s)",
      item: "Tofu",
      description: "The serving size for Tofu is typically around 3 to 4 ounces for adults and half the amount for a kid per meal.",
      buyLink: "https://www.amazon.com/Mori-Nu-Silken-Tofu-Extra-Ounce/dp/B000LKZ86K/ref=sr_1_2?crid=5RUPS8DTP6WQ&keywords=tofu&qid=1687922198&s=grocery&sprefix=tofu%252Cgrocery%252C134&sr=1-2&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=d93453e35b09f8af09131a8ceddc5b3b&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Cup(s)",
      item: "Veggies",
      description: "1/2 cup per adult, 1/4 cup per child per meal.",
      buyLink: "https://www.amazon.com/Amazon-Brand-Happy-Belly-Sweet/dp/B07VKG1TFH?ref_=ast_sto_dp&th=1&psc=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=eba23572c002943e1d38006e5947a65f&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Cup(s)",
      item: "Fruit",
      description: "1/2 cup per adult, 1/4 cup per child per meal.",
      buyLink: "https://www.amazon.com/Amazon-Brand-Happy-Mandarin-Orange/dp/B07WYXGQ97?ref_=ast_sto_dp&th=1&psc=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=8e5d8e18b8e943e0979867d94aeb3a24&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Cup(s)",
      item: "Dried Fruits or Nuts",
      description: "1/4 cup per individual.",
      buyLink: "https://www.amazon.com/Amazon-Brand-Happy-Belly-Cranberry/dp/B071XFHZNR?ref_=ast_sto_dp&th=1&psc=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=4d5b09af4f73c990fd1e447ceca6971d&camp=1789&creative=9325"
    },
    {
      quantity: "variable",
      unit: "Bar(s)",
      item: "Granola or Protein",
      description: "1 bar per adult, 1/2 bar per child per meal.",
      buyLink: "https://www.amazon.com/Amazon-Brand-Happy-Almond-Granola/dp/B0886WWGCB?ref_=ast_sto_dp&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=97f6295b9b65ae3f96359b2f8ad2f26f&camp=1789&creative=9325"
    },
    {
        quantity: "variable",
        unit: "Cup(s)",
        item: "Dry Cereal or Granola",
        description: "A good source of carbohydrates, dry cereal or granola is easy to store and consume during emergencies.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=bcbdc63e412f4d9d0e1d8b0360ebf0c1&camp=1789&creative=9325&index=grocery&keywords=Family%20Size-dry%20cereal-granola"
      },
      {
        quantity: "variable",
        unit: "tblsp",
        item: "Peanut Butter",
        description: "Rich in protein and fats, peanut butter is a compact, high-energy, and delicious food ideal for emergency food supplies.",
        buyLink: "https://www.amazon.com/Amazon-Brand-Crunchy-Peanut-Butter/dp/B07KWD9K4C?ref_=ast_sto_dp&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=734d3d7c931a2ce08ba6c9187b641476&camp=1789&creative=9325"
      },
      {
        quantity: "variable",
        unit: "Ounces",
        item: "Of Crackers",
        description: "Choose salt-free or low-sodium, whole-grain crackers for a convenient snack that requires no preparation.",
        buyLink: "https://www.amazon.com/Amazon-Brand-Original-Saltine-Crackers/dp/B07WSTZ95G?ref_=ast_sto_dp&th=1&psc=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=056b038cb180d73b9d576b8def0ceee6&camp=1789&creative=9325"
      },
      {
        quantity: "variable",
        unit: "Cup(s)",
        item: "Bottled or Boxed Juices",
        description: "Store bottled or boxed juices as a source of vitamins and quick energy. Ensure they are 100% juice.",
        buyLink: "https://www.amazon.com/Amazon-Brand-Happy-Belly-Concentrate/dp/B07WKLC8CV?ref_=ast_sto_dp&th=1&psc=1&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=41e150ac3b606fea3795ec6c3f7ec2e7&camp=1789&creative=9325"
      },
      {
        quantity: "variable",
        unit: "8fl oz",
        item: "Shelf Stable or PDR. Milk",
        description: "Shelf-stable milk can be stored without refrigeration, making it ideal for emergency food supplies.",
        buyLink: "https://www.amazon.com/Horizon-Organic-Whole-Ounce-Single/dp/B07MQ6DB8Z?ref_=ast_sto_dp&_encoding=UTF8&tag=sitprep0b-20&linkCode=ur2&linkId=ca76b61b7eed5e92bcc052d194abc3de&camp=1789&creative=9325"
      },
      {
        quantity: "variable",
        unit: "Cup(s)",
        item: "Adult Vitamins",
        description: "Maintain health with daily multivitamins to provide essential nutrients that might be missing from emergency diets.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=5e852e50ea0a7284290125b133ce4d56&camp=1789&creative=9325&index=grocery&keywords=Adult%20Vitamins"
      },
      {
        quantity: "variable",
        unit: "Cup(s)",
        item: "Children's Multivitamins",
        description: "Ensure children receive their daily nutritional needs with specially formulated multivitamins for kids.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=987f18fc568fbd974130617c183551e7&camp=1789&creative=9325&index=grocery&keywords=Children's%20Multivitamins"
      },
      {
        quantity: "variable",
        unit: "3 days",
        item: "Worth of baby formula",
        description: "Keep an adequate supply of baby formula to ensure infants have necessary nutrition if breastfeeding is not possible.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=06776e822a68f0b9fe44a389b95b4fd0&camp=1789&creative=9325&index=grocery&keywords=baby%20formula"
      },
      {
        quantity: "variable",
        unit: "3 days",
        item: "Worth of baby food",
        description: "Stock up on jars or pouches of baby food appropriate for the age and development stage of your infant.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=d1c4d9950d891520b04e685f63bb1580&camp=1789&creative=9325&index=grocery&keywords=baby%20food"
      },
      {
        quantity: "variable",
        unit: "3 days",
        item: "Worth of dog food",
        description: "Ensure your pets are well-fed during emergencies by storing enough food to last at least three days.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=0d21e0787d924ca74e30a42974f8c405&camp=1789&creative=9325&index=grocery&keywords=dog%20food"
      },
      {
        quantity: "variable",
        unit: "3 days",
        item: "Worth of cat food",
        description: "Keep a sufficient supply of cat food to ensure your feline's needs are met during an emergency.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=d93b041ec32f3180de14ba83a7eeaccf&camp=1789&creative=9325&index=grocery&keywords=cat%20food"
      },
      {
        quantity: "variable",
        unit: "3 days",
        item: "Worth of pet food",
        description: "Include a three-day supply of food for any other pets you may have to ensure their well-being during an emergency.",
        buyLink: "https://www.amazon.com/gp/search?ie=UTF8&tag=sitprep-20&linkCode=ur2&linkId=298668848dc71a84ed240c4b96791aff&camp=1789&creative=9325&index=grocery&keywords=pet%20food"
      }
      // Ensure all items are added correctly.
      ];
      
  

const ShoppingListTableTailwind = () => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const checkboxRef = useRef();
  
    useEffect(() => {
      setIsAllSelected(selectedItems.length === items.length);
      checkboxRef.current.indeterminate = selectedItems.length > 0 && selectedItems.length < items.length;
    }, [selectedItems]);
  
    const toggleSelectAll = () => {
      if (isAllSelected) {
        setSelectedItems([]);
      } else {
        setSelectedItems(items.map(item => item.id));
      }
    };
  
    const toggleItemSelection = (id) => {
      if (selectedItems.includes(id)) {
        setSelectedItems(selectedItems.filter(item => item !== id));
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    };
  
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-semibold text-gray-900">Shopping List</h1>
        <p className="mt-2 text-sm text-gray-700">Select items you need to buy from Amazon.</p>
  
        <div className="mt-8 flex flex-col">
          <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <input
                      type="checkbox"
                      checked={isAllSelected}
                      ref={checkboxRef}
                      onChange={toggleSelectAll}
                      className="rounded"
                    />
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Item</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantity</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Unit</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Buy on Amazon</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {items.map((item) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      <input
                        type="checkbox"
                        checked={selectedItems.includes(item.id)}
                        onChange={() => toggleItemSelection(item.id)}
                        className="rounded"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.item}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.quantity}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.unit}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.description}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a href={item.buyLink} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                        Buy
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

export default ShoppingListTableTailwind;
