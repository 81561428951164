import React, { useState, useEffect } from 'react';
import { FaCoffee, FaAppleAlt, FaPizzaSlice, FaCarrot } from 'react-icons/fa';

const FoodMenuComponent = () => {
  const defaultMenus = [
    { id: 1, meals: { breakfast: 'Cereal with Milk and Fruit', lunch: 'Tuna and Crackers Combo', dinner: 'Beef Stew and Crackers Combo', snack: 'Granola Bar' }},
    { id: 2, meals: { breakfast: 'Cereal with Milk, Fruit, and Nuts', lunch: 'Chilli and Crackers', dinner: 'Tofu with Veggies and Crackers', snack: 'Nuts or Dried Fruits' }},
    { id: 3, meals: { breakfast: 'Cereal with Milk, Fruit, and Granola Bar', lunch: 'Ravioli with Fruits and Crackers', dinner: 'Tuna and Crackers Combo', snack: 'Peanut Butter and Crackers' }}
  ];

  const mealOptions = {
    breakfast: ["Cereal with Milk and Fruit", "Cereal with Milk, Fruit, and Nuts", "Cereal with Milk, Fruit, and Granola Bar", "Cereal with Milk, Fruit, and Peanut Butter"],
    lunch: ["Tuna and Crackers Combo", "Chilli and Crackers", "Beef Stew and Crackers Combo", "Ravioli with Fruits and Crackers", "Tofu with Veggies and Crackers"],
    dinner: ["Tuna and Crackers Combo", "Chilli and Crackers", "Beef Stew and Crackers Combo", "Ravioli with Fruits and Crackers", "Tofu with Veggies and Crackers"],
    snack: ["Granola Bar", "Nuts or Dried Fruits", "Crackers", "Peanut Butter and Crackers"]
  };

  const initialMenuOptions = parseInt(localStorage.getItem('numberOfMenuOptions')) || 3;
  const initialPlanDuration = JSON.parse(localStorage.getItem('planDuration')) || { quantity: 3, unit: 'days' };
  const initialMenus = JSON.parse(localStorage.getItem('mealPlan')) || defaultMenus;

  const [numberOfMenuOptions, setNumberOfMenuOptions] = useState(initialMenuOptions);
  const [planDuration, setPlanDuration] = useState(initialPlanDuration);
  const [menus, setMenus] = useState(initialMenus);

  
  useEffect(() => {
    if (numberOfMenuOptions > menus.length) {
      const additionalMenus = generateAdditionalMenus(numberOfMenuOptions, menus);
      setMenus([...menus, ...additionalMenus]);
    } else {
      setMenus(menus.slice(0, numberOfMenuOptions));
    }
  }, [numberOfMenuOptions]);

  const generateAdditionalMenus = (count, existingMenus) => {
    return Array.from({ length: count - existingMenus.length }, (_, index) => ({
      id: existingMenus.length + index + 1,
      meals: {
        breakfast: '',
        lunch: '',
        dinner: '',
        snack: ''
      }
    }));
  };


  const handleMealSelection = (menuId, mealType, value) => {
    const updatedMenus = menus.map(menu =>
      menu.id === menuId ? { ...menu, meals: { ...menu.meals, [mealType]: value } } : menu
    );
    setMenus(updatedMenus);
  };

  const saveMealPlan = () => {
    const mealPlanWithIngredients = menus.map(menu => ({
      id: menu.id,
      meals: menu.meals,
      ingredients: Object.entries(menu.meals).reduce((acc, [mealType, meal]) => {
        const ingredients = mapMealToIngredients(meal);
        acc[mealType] = ingredients;
        return acc;
      }, {})
    }));
    localStorage.setItem('mealPlan', JSON.stringify(mealPlanWithIngredients));
    localStorage.setItem('planDuration', JSON.stringify(planDuration));
    localStorage.setItem('numberOfMenuOptions', JSON.stringify(numberOfMenuOptions));
    console.log('Meal Plan Saved:', mealPlanWithIngredients);
  };

  const mapMealToIngredients = (meal) => {
    const mealMapping = {
      "Cereal with Milk and Fruit": ["Cereal", "Milk", "Fruit"],
      "Cereal with Milk, Fruit, and Nuts": ["Cereal", "Milk", "Fruit", "Nuts"],
      "Cereal with Milk, Fruit, and Granola Bar": ["Cereal", "Milk", "Fruit", "Granola Bar"],
      "Cereal with Milk, Fruit, and Peanut Butter": ["Cereal", "Milk", "Fruit", "Peanut Butter"],
      "Tuna and Crackers Combo": ["Tuna", "Crackers", "Fruit or Vegetables"],
      "Chilli and Crackers": ["Chilli", "Crackers", "Fruit or Vegetables"],
      "Beef Stew and Crackers Combo": ["Beef Stew", "Crackers", "Fruit or Vegetables"],
      "Tofu with Veggies and Crackers": ["Tofu", "Veggies", "Crackers"],
      "Ravioli with Fruits and Crackers": ["Ravioli", "Fruits", "Crackers"],
      "Granola Bar": ["Granola Bar"],
      "Nuts or Dried Fruits": ["Nuts", "Dried Fruits"],
      "Crackers": ["Crackers"],
      "Peanut Butter and Crackers": ["Peanut Butter", "Crackers"]
    };
    return mealMapping[meal] || [];
  };

  const getIcon = (mealType) => {
    switch(mealType) {
      case 'breakfast': return <FaCoffee className="inline mb-1" />;
      case 'lunch': return <FaAppleAlt className="inline mb-1" />;
      case 'dinner': return <FaPizzaSlice className="inline mb-1" />;
      case 'snack': return <FaCarrot className="inline mb-1" />;
      default: return null;
    }
  };

  return (
    <div className="mx-auto max-w-7xl mt-10">
      <h1 className="text-2xl font-bold text-white text-center my-6">Plan Your Emergency Food Menu</h1>
      <div className="mb-6 text-center">
        <label className="text-white font-bold mr-4">Number of Menu Options:</label>
        <select 
          value={numberOfMenuOptions} 
          onChange={(e) => setNumberOfMenuOptions(parseInt(e.target.value))}
          className="p-2 bg-gray-800 text-white rounded-md"
        >
          {[3, 4, 5, 6, 7].map(option => <option key={option} value={option}>{`${option} Options`}</option>)}
        </select>
      </div>

      <div className="mb-6 text-center">
        <label className="text-white font-bold mr-4">Plan Duration:</label>
        <input 
          type="number" 
          value={planDuration.quantity} 
          onChange={(e) => setPlanDuration({ ...planDuration, quantity: parseInt(e.target.value) })}
          min="1" 
          className="p-2 w-20 bg-gray-800 text-white rounded-md mr-2"
        />
        <select 
          value={planDuration.unit} 
          onChange={(e) => setPlanDuration({ ...planDuration, unit: e.target.value })}
          className="p-2 bg-gray-800 text-white rounded-md"
        >
          {['days', 'weeks', 'months'].map(unit => <option key={unit} value={unit}>{unit}</option>)}
        </select>
      </div>

      {/* Render menus based on the state of menus */}
      <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-3">
        {menus.map((menu) => (
          <div key={menu.id} className="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
            <h2 className="text-xl font-bold text-white mb-4">Menu {menu.id}</h2>
            {Object.entries(menu.meals).map(([mealType, mealValue]) => (
              <div key={mealType} className="mb-3">
                <label htmlFor={`menu${menu.id}-${mealType}-select`} className="form-label text-white">
                  {getIcon(mealType)} {mealType.charAt(0).toUpperCase() + mealType.slice(1)}:
                </label>
                <select
                  id={`menu${menu.id}-${mealType}-select`}
                  value={mealValue}
                  onChange={(e) => handleMealSelection(menu.id, mealType, e.target.value)}
                  className={`w-full p-2 rounded-md ${mealValue ? 'bg-green-600' : 'bg-gray-800'} text-white`}
                  aria-label={`${mealType} Selection`}
                >
                  <option value="" disabled>Select a {mealType} Option</option>
                  {mealOptions[mealType].map(option => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        ))}
      </div>
      <button onClick={saveMealPlan} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Next
      </button>
    </div>
  );
};

export default FoodMenuComponent;
