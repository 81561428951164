import React, { useState, useEffect } from 'react';
import FoodMenuComponent from './foodmenu';
import ShoppingListTable from './shoppinglistcomp';

const FoodSupplyPage = () => {
  const [demographics, setDemographics] = useState({
    infants: 0,
    adults: 0,
    kids: 0,
    dogs: 0,
    cats: 0,
    pets: 0,
  });
  
  useEffect(() => {
    const storedData = localStorage.getItem("demoData");
    if (storedData) {
      setDemographics(JSON.parse(storedData));
    }
  }, []);

  const saveDemo = () => {
    localStorage.setItem("demoData", JSON.stringify(demographics));
  };

  const updateDemographics = (field, value) => {
    const newDemographics = { ...demographics, [field]: value };
    setDemographics(newDemographics);
    saveDemo();
  };

  const demographicStats = [
    { name: 'Infants', id: 'infants' },
    { name: 'Kids', id: 'kids' },
    { name: 'Adults', id: 'adults' },
    { name: 'Dogs', id: 'dogs' },
    { name: 'Cats', id: 'cats' },
    { name: 'Other Pets', id: 'pets' },
  ];

  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl">
        <h1 className="text-2xl font-bold text-white text-center my-6">Household Demographic</h1>
        <p className="text-center text-gray-400 mb-6">Providing information about those in your household will help create an emergency list that meets the needs of everyone.</p>
        <div className="grid grid-cols-1 gap-px bg-white/5 sm:grid-cols-2 lg:grid-cols-3">
          {demographicStats.map((stat) => (
            <div key={stat.name} className="bg-gray-900 px-4 py-6 sm:px-6 lg:px-8">
              <p className="text-sm font-medium text-gray-400 mb-2">{stat.name}</p>
              <div className="flex items-center justify-center gap-4">
                <button 
                  className="p-2 bg-gray-700 rounded-full hover:bg-gray-600" 
                  onClick={() => updateDemographics(stat.id, Math.max(0, demographics[stat.id] - 1))}
                >
                  <i className="fas fa-minus text-white"></i>
                </button>
                <input 
                  type="number"
                  min="0"
                  value={demographics[stat.id]}
                  onChange={(e) => updateDemographics(stat.id, parseInt(e.target.value))}
                  className="w-16 text-center border border-gray-500 rounded-md bg-gray-800 text-white"
                />
                <button 
                  className="p-2 bg-gray-700 rounded-full hover:bg-gray-600" 
                  onClick={() => updateDemographics(stat.id, demographics[stat.id] + 1)}
                >
                  <i className="fas fa-plus text-white"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* FoodMenuComponent integrated here */}
      <FoodMenuComponent />
      <ShoppingListTable/>
    </div>
  );
};

export default FoodSupplyPage;
