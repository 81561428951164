import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateUser, fetchUserByEmail } from '../Api/apiService';

export default function ChangeStatusPage() {
  const navigate = useNavigate();

  const currentUserEmail = localStorage.getItem('currentUserEmail');
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem(`userData-${currentUserEmail}`)) || {});
  const [tempStatus, setTempStatus] = useState({
    userStatus: userData.userStatus || '',
    statusColor: '',
    userStatusLastUpdated: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const fetchedUserData = await fetchUserByEmail(currentUserEmail);
        localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(fetchedUserData));
        setUserData(fetchedUserData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [currentUserEmail]);

  const handleStatusChange = (newStatus) => {
    let newStatusColor = '';
    switch (newStatus) {
      case 'SAFE':
        newStatusColor = '#1BBC9B';
        break;
      case 'INJURED':
        newStatusColor = '#F25A7C';
        break;
      case 'HELP':
        newStatusColor = '#FFC107';
        break;
      default:
        newStatusColor = '#1BBC9B';
    }

    setTempStatus({
      userStatus: newStatus,
      statusColor: newStatusColor,
      userStatusLastUpdated: new Date().toISOString()
    });
  };

  const handleSaveProfile = async () => {
    const updatedUserData = {
      ...userData,
      ...tempStatus
    };

    try {
      await updateUser(userData.id, updatedUserData);
      localStorage.setItem(`userData-${currentUserEmail}`, JSON.stringify(updatedUserData));
      setUserData(updatedUserData);
      navigate('/home');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10 px-4 py-8">
      <div className="gap-x-8 gap-y-8 pt-10">
        <div className="sm:col-span-4">
          <label htmlFor="userStatus" className="block text-sm font-medium leading-6 text-gray-900">Status</label>
          <p className="mt-1 text-sm leading-6 text-gray-600">Update your current status.</p>
          <input
            type="text"
            name="userStatus"
            id="userStatus"
            value={tempStatus.userStatus}
            className="ps-3 mt-2 block w-full rounded-md border border-gray-300 py-1.5 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            disabled
          />
        </div>

        <div className="my-4 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
            <button
              type="button"
              className="col-span-1 rounded-md bg-red-100 text-red-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-200"
              onClick={() => handleStatusChange('INJURED')}
            >
              I'm INJURED
            </button>
            <button
              type="button"
              className="col-span-1 rounded-md bg-yellow-100 text-yellow-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-yellow-300 hover:bg-yellow-200"
              onClick={() => handleStatusChange('HELP')}
            >
              I need HELP
            </button>
            <button
              type="button"
              className="col-span-1 rounded-md bg-green-100 text-green-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-200"
              onClick={() => handleStatusChange('SAFE')}
            >
              I'm SAFE
            </button>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={() => navigate('/home')}>Cancel</button>
          <button type="button" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleSaveProfile}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
